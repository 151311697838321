import { useEffect, useState } from 'react';
import {urls} from "../constants";

import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { Grid } from '@material-ui/core';
import Collapse from '@mui/material/Collapse';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import MuiListItem from "@material-ui/core/ListItem";
import { ExpandLess } from "@material-ui/icons";
import { ExpandMore } from "@material-ui/icons";

const base_url = urls.base_url;
const base_url_query_table = urls.base_url_query_table;

const MissingData = (props) => {
  const allToggledOff = Object.entries(props.toggleOpen).every(([k, v], _) => {
    // ignore linking_studies or missing_studies dropdowns when determining allToggle status 
    // (missing patients only)
    return k === 'linking_studies' | k === 'missing_studies' | !v
  })

  const [missingPatients, setMissingPatients] = useState([]);
  const [missingS3Studies, setMissingS3Studies] = useState([]);
  const [missingCMRStudies, setMissingCMRStudies] = useState([]);
  const [loadingPatients, setLoadingPatients] = useState(true);
  const [loadingS3Studies, setLoadingS3Studies] = useState(true);
  const [loadingCMRStudies, setLoadingCMRStudies] = useState(true);

  const db_patients_check_url = `${base_url_query_table}?cmd=check_database_integrity`;
  const studies_check_url = `${base_url_query_table}?cmd=find_missing_studies`;
  const cmr_check_url = `${base_url}/identify_studies`;

  const fetchMissingPatients = () => {
    fetch(db_patients_check_url, { headers: { "Authorization": props.authToken } })
    .then((response) => response.json())
    .then((data) => {
      if(data.message === "The incoming token has expired") {
        props.logoutCallback(true);
      }
      
      if (typeof(data) !== 'object') {
        setMissingPatients([])
        setLoadingPatients(true);
        console.log('fetchMissingPatients unexpected resp: %s', data)
      } else {

        // Remove any "dev_" or "stage_" from table names
        data.invalid_patients = Object.entries(data.invalid_patients).reduce((acc, [key, val]) => {
          acc[key.replace(/\w+_/,'')] = val
          return acc
        }, {})
        setMissingPatients(data);
        setLoadingPatients(false);
      }
    }).catch(() => {
        //console.log("Failed to fetch!", props.logoutCallback);
        props.logoutCallback(true);
    });
  }

  const fetchMissingS3Studies = () => {
    fetch(studies_check_url, { headers: { "Authorization": props.authToken } })
    .then((response) => response.json())
    .then((data) => {
      if(data.message === "The incoming token has expired") {
        props.logoutCallback(true);
      }

      if (typeof(data) !== 'object') {
        setMissingS3Studies([])
        setLoadingS3Studies(true);
        console.log('fetchMissingStudies unexpected resp: %s', data)
      } else {
        setMissingS3Studies(data);
        setLoadingS3Studies(false);
      }
    })
    .catch(() => {
      props.logoutCallback(true);
    });
  }

  const fetchMissingCMRStudies = () => {
    fetch(cmr_check_url, { headers: { "Authorization": props.authToken } })
    .then((response) => response.json())
    .then((data) => {
      if(data.message === "The incoming token has expired") {
        props.logoutCallback(true);
      }

      if (typeof(data) !== 'object') {
        setMissingCMRStudies([])
        setLoadingCMRStudies(true);
        console.log('fetchMissingStudies unexpected resp: %s', data)
      } else {
        setMissingCMRStudies(data);
        setLoadingCMRStudies(false);
      }
    })
    .catch(() => {
      props.logoutCallback(true);
    });
  }

  useEffect(() => {
    fetchMissingPatients();
    fetchMissingS3Studies();
    fetchMissingCMRStudies();
    console.log(missingPatients);
    console.log(missingS3Studies);
    console.log(missingCMRStudies);
  }, [])

  return (
    <section className="container">
      <>
          <MuiListItem button onClick={() => {props.handleToggleAll(allToggledOff)}} variant="h4">
          {/* <MuiListItem button onClick={() => {handleToggleAll(true)}} variant="h4"> */}
            <ListItemText primary= {
              <Typography sx={{fontSize: 18}}>{
                "Missing Patients: Tables with Patient IDs missing from Patient table"
                }
              </Typography>}/>
              {!allToggledOff ? <ExpandLess sx={{fontSize: 30}}/> : <ExpandMore sx={{fontSize: 30}}/>}
          </MuiListItem>
          <List>
            <Collapse in={!allToggledOff} timeout="auto" unmountOnExit>
              { loadingPatients ? <CircularProgress size={100} /> : 
                (missingPatients.invalid_patients && Object.keys(missingPatients.invalid_patients))?.map(key => (
                <Box key={key}>
                  <List sx={{ paddingTop: 0, paddingBottom: 0, width: '100%', maxWidth: 425}} >
                    <MuiListItem button onClick={() => {props.handleToggleOpen(key)}} >
                      <ListItemText primary= {<Typography sx={{fontSize: 18}}>{key}</Typography>}  />
                      {props.toggleOpen[key] ? <ExpandLess sx={{fontSize: 30}}/> : <ExpandMore sx={{fontSize: 30}}/>}
                    </MuiListItem>
                    <MissingList 
                      toggle={props.toggleOpen[key]}
                      loading={loadingPatients}
                      missinglist={missingPatients.invalid_patients[key]}
                    />
                    {/* <Collapse in={props.toggleOpen[key]} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        {
                          missingPatients.invalid_patients[key]?.map(patientID => (
                            <MuiListItem disablePadding> 
                              <Grid>{patientID}</Grid>
                            </MuiListItem>
                        ))
                        }
                      </List>
                    </Collapse> */}
                  </List>
                </Box>
              ))}
            </Collapse>
          </List>
          <Box>
            <List sx={{ paddingTop: 0, paddingBottom: 0, width: '100%'}} >
            <MuiListItem button onClick={() => {props.handleToggleOpen('missing_studies')}} variant="h4">
                <ListItemText primary= {<Typography sx={{fontSize: 18}}>{ 'Erroneously linked Study IDs: These studies have been linked in CMR with no upload' }</Typography>}  />
                {props.toggleOpen['missing_studies'] ? <ExpandLess sx={{fontSize: 30}}/> : <ExpandMore sx={{fontSize: 30}}/>}
            </MuiListItem>
            <MissingList 
              toggle={props.toggleOpen['missing_studies']}
              loading={loadingS3Studies}
              missinglist={missingS3Studies.missing_studies}
            />
            </List>
            </Box>
            <List sx={{ paddingTop: 0, paddingBottom: 0, width: '100%'}} >
            <MuiListItem button onClick={() => {props.handleToggleOpen('linking_studies')}} variant="h4">
              <ListItemText primary= {<Typography sx={{fontSize: 18}}>{ 'Missing CMR Entries: Study IDs missing from CMR table that have been uploaded (CSV upload needed)' }</Typography>}  />
              {props.toggleOpen['linking_studies'] ? <ExpandLess sx={{fontSize: 30}}/> : <ExpandMore sx={{fontSize: 30}}/>}
            </MuiListItem>
            <MissingList 
              toggle={props.toggleOpen['linking_studies']}
              loading={loadingCMRStudies}
              missinglist={missingCMRStudies.map(([study_id, patient_id, age]) => study_id)}
            />
            </List>
        </>
    </section>
  );
}

const MissingList = ({ toggle, loading, missinglist }) => (
  <Collapse in={toggle} timeout="auto" unmountOnExit>
    <List component="div" sx={{fontSize: 16, paddingLeft: 2}}>
      {loading ? (
        <CircularProgress size={100} />
      ) : (
        <ul style={{listStyle: 'Disc', marginLeft: '2vw' }}>
            {missinglist.map((data) => {
                return <li key={data} style={{fontSize: 16, marginBottom: 2}}>{data}</li>
            })}
        </ul>
      )}
    </List>
  </Collapse>
);

export default MissingData;