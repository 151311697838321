import { useState, useEffect } from "react"
import { Grid } from "@material-ui/core";
import { Select } from '@material-ui/core';
import MenuItem from '@mui/material/MenuItem';
import { Button, Typography } from "@mui/material";
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { urls } from "../constants";

const CSVTemplates = (props) => {

    const [blankTemplate, setBlankTemplate] = useState("")

    const handleBlankTemplate = (event) => {
        setBlankTemplate(event.target.value)
    }

    const base_url = urls.base_url_query_table;

    const url = `${base_url}?cmd=get_blank_csv&table_name=${blankTemplate}`

    const handleDownload = async () => {
        try {
        const response = await fetch(url, {
          headers: {
            Authorization: props.authToken
          }
        });
  
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
  
        const blob = await response.blob();
        const blobURL = URL.createObjectURL(blob);
  
        const link = document.createElement('a');
        link.href = blobURL;
        link.download = `${blankTemplate}_template.csv`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error("There was an error downloading the CSV:", error);
      }
    }

    const handleDownloadAlert = () => {
        props.setOpenAlert(22);
    }

    return (
        <section className="container">
            <Grid display="block" direction="column" container>

                <Grid item>
                    <p>Select a table and download a blank CSV template.</p>
                </Grid>

                <Grid item>
                    <Select inputProps={{fontSize: 16}} 
                    variant="filled" 
                    style={{fontSize: 16, width: 300, height: 50, backgroundColor: 'white', border: 3, borderColor: 'black', border: "1px solid black",
                    color: "black",
                    "& .MuiSvgIcon-root": {
                        color: "black",
                        fontSize: 16
                    },}} 
                    labelId='blankTemplate' 
                    onChange={handleBlankTemplate} 
                    value={blankTemplate}>
                    <MenuItem style={{fontSize: 16}} value={props.tableNames["patient"]}>{props.tableNames.patient}</MenuItem>
                    <MenuItem style={{fontSize: 16}} value={props.tableNames["cmr"]}>{props.tableNames.cmr}</MenuItem>
                    <MenuItem style={{fontSize: 16}} value={props.tableNames["echo"]}>{props.tableNames.echo}</MenuItem>
                    <MenuItem style={{fontSize: 16}} value={props.tableNames["cath"]}>{props.tableNames.cath}</MenuItem>
                    <MenuItem style={{fontSize: 16}} value={props.tableNames["ecg"]}>{props.tableNames.ecg}</MenuItem>
                    <MenuItem style={{fontSize: 16}} value={props.tableNames["cpet"]}>{props.tableNames.cpet}</MenuItem>
                    <MenuItem style={{fontSize: 16}} value={props.tableNames["procedure"]}>{props.tableNames.procedure}</MenuItem>
                    <MenuItem style={{fontSize: 16}} value={props.tableNames["clinicalnotes"]}>{props.tableNames.clinicalnotes}</MenuItem>
                    </Select>
                </Grid>

                <Grid item>
                    {
                    blankTemplate ? 
                    <Button onClick={handleDownload} style={{textTransform: 'none', backgroundColor: "#009be5", marginTop: 10, marginBottom: 15}} sx={{ paddingTop: 1, paddingRight: 1,marginRight: 3, width: 'fit-content', 
                    ':hover': {
                    backgroundColor: '#000',
                    color: '#3c52b2',
                    }
                    }}>
                        <Typography sx={{ fontSize: 16, color: "white"}} variant="p">
                        Download
                        </Typography>
                    </Button> : 
                    <Button onClick={handleDownloadAlert} style={{textTransform: 'none', backgroundColor: "rgba(0, 155, 229, 0.6)", marginTop: 10, marginBottom: 15}} sx={{ paddingTop: 1, paddingRight: 1,marginRight: 3, width: 'fit-content', 
                    ':hover': {
                    backgroundColor: '#000',
                    color: '#3c52b2',
                    }
                    }}>
                        <Typography sx={{ fontSize: 16, color: "white"}} variant="p">
                        Download
                        </Typography>
                    </Button>
                    }
                </Grid>

            </Grid>
        </section>
    )

}

export default CSVTemplates