import React, { useState } from 'react';
import DetailsDropdown from './DetailsDropdown';

const StudyUpload = (props) => {
    const style = props.style;

    return (
        <section className="container">
            <h4 style={{'margin-bottom': '0.9em'}}>Instructions for uploading files for a study, including DICOM image and other files:</h4>
            <p style={{'margin-bottom': '0.9em'}} className="tab">
                Each patient in the database can have associated de-identified study files. Study folders containing these files have the naming convention: CHD + 5-digit "patient id" + 2-digit "study id", i.e. CHD1234501
            </p>
            <p style={{'margin-bottom': '0.5em'}} className="tab">
                DICOM images are the basic file type associated with a study. When a patient has DICOM image files, the study folder is “linked” to the patient and shown in the CMR table.
            </p>
            <p style={{'margin-bottom': '0.5em'}} className="tab">
                Other file types can be stored in a study folder, for example NIFTI files. When downloaded, these other file types are placed in subfolders in the main study folder. DICOM files are stored directly in the study folder.
            </p>
            <p style={{'margin-bottom': '0.5em'}} className="tab">
                Uploads are done for any one file type at a time. To upload, folders must be prepared in the following fashion, organized as files within a study folder for each patient:
            </p>
            <ul style={{fontSize: '13px'}} className='tab-large'>
                <li style={{marginBottom: '0.9em'}}>A patient folder for each patient, containing all of the folders and files to be uploaded. This patient folder should have the naming convention: CHD + 5-digit “patient id", i.e. CHD12345.</li>
                <li style={{marginBottom: '0.9em'}}>Contained within this patient folder should be one or more “study” folders. A study folder has the naming convention: CHD + 5-digit "patient id" + 2-digit "study id", i.e. CHD1234501.</li>
                <li style={{marginBottom: '0.9em'}}>The study folder(s) contain the files to be uploaded, of the same type (i.e. all DICOM or all NIFTI). The user selects the type of data being uploaded. File type and extensions are ignored. The patient folder contains only study folders with no additional subfolders.</li>
            </ul>
            <p style={{'margin-bottom': '0.5em'}} className="tab">
                No more than 20,000 files can be uploaded at once.
            </p>
            <p style={{'margin-bottom': '0.5em'}} className="tab">
                Information about collision/duplicate detection:
            </p>
            <ul style={{fontSize: '13px', listStyleType: "disc"}} className='tab-large'>
                <li style={{marginBottom: '0.9em'}}>Uploading new files to an existing study will prompt to confirm UPLOADS of new files under an existing study id (collision detection)</li>
                <li style={{marginBottom: '0.9em'}}>Uploading new files with same name of previously uploaded files will prompt to confirm OVERWRITES of duplicate files under an existing study id</li>
            </ul>
            <DetailsDropdown isTab={true} title="Click for more details on collision/duplicate detection">
                <p style={{'margin-bottom': '0.5em', 'margin-top': '0.5em', fontSize: '13px'}} className="tab">
                    Note regarding collisions. If a collision is detected with NO duplicate files, we assume the following scenario:
                </p>
                <ol style={{fontSize: '13px', listStyleType: 'decimal'}} className={'tab'} >
                    <li style={{marginBottom: '0.9em', marginLeft: '2em'}}>Researcher A does a scan and uploads 10K files to study CHD9925301</li>
                    <li style={{marginBottom: '0.9em', marginLeft: '2em'}}>Researcher B also does a scan and uploads 10K file to study CHD9925301 (they don't know that Researcher A already uploaded under that study ID)</li>
                    <li style={{marginBottom: '0.9em', marginLeft: '2em'}}>This event is a collisions and we prompt the user to avoid 2 sets of study image files being uploaded under the same id</li>
                </ol>
                <p style={{'margin-bottom': '0.5em', 'margin-top': '0.5em', fontSize: '13px'}} className="tab">
                    If there are ANY duplicate files at all, we are assuming that there is no collision. We assume a different scenario:
                </p>
                <ol style={{fontSize: '13px', listStyleType: 'decimal'}} className={'tab'} >
                    <li style={{marginBottom: '0.9em', marginLeft: '2em'}}>Researcher A does a scan and uploads 10K files to study CHD9925301</li>
                    <li style={{marginBottom: '0.9em', marginLeft: '2em'}}>Researcher A needs to add 1K more files to study CHD9925301 (either he forgot to in the beginning, or his network connection got interrupted)</li>
                    <li style={{marginBottom: '0.9em', marginLeft: '2em'}}>In this event, the user is only prompted to check if they would like to re-upload and overwrite their duplicate uploads</li>
                </ol>
            </DetailsDropdown>

                <hr />
                
            
            <div>
                <label htmlFor="format-select">Choose a Category: </label>
                <select id="format-select" value={props.category_name} onChange={props.handleCategoryChange}>
                    <option value="DICOM">DICOM</option>
                    <option value="CONTINUITY">CONTINUITY</option>
                    <option value="NIFTI">NIFTI</option>
                    <option value="CIM">CIM</option>
                    <option value="OTHER">OTHER</option>
                </select>
            </div>

            <div {...props.getRootProps({style})}>
                <input type="file" directory="" webkitdirectory="" multiple sx={{ height:500 }} {...props.getInputProps()} />
                <p>Drag and drop one or more patient folders here to upload, or click to upload one patient folder</p>
            </div>
            <aside>
                {
                    props.numAcceptedFiles !== 0 ? <>
                    <div>
                        <h1 style={{ fontSize: "24px", textAlign: "center", marginBottom: "20px" }}>
                        Upload Status
                        </h1>
                    
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "20px" }}>
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                            <span style={{ fontSize: "18px", fontWeight: "bold" }}>Total attempted uploads</span>
                            <span style={{ fontSize: "36px", fontWeight: "bold", color: "#333" }}>{props.numAcceptedFiles}</span>
                        </div>
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                            <span style={{ fontSize: "18px", fontWeight: "bold" }}>Successful uploads</span>
                            <span style={{ fontSize: "36px", fontWeight: "bold", color: "#4caf50" }}>{props.uploadCount}</span>
                        </div>
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                            <span style={{ fontSize: "18px", fontWeight: "bold" }}>Rejected uploads</span>
                            <span style={{ fontSize: "36px", fontWeight: "bold", color: "#ff9800" }}>{props.rejected_uploads_state.length}</span>
                        </div>
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                            <span style={{ fontSize: "18px", fontWeight: "bold" }}>Failed uploads</span>
                            <span style={{ fontSize: "36px", fontWeight: "bold", color: "#f44336" }}>{props.failed_uploads_state.length}</span>
                        </div>
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                            <span style={{ fontSize: "18px", fontWeight: "bold" }}>Remaining uploads</span>
                            <span style={{ fontSize: "36px", fontWeight: "bold", color: "#2196f3" }}>{Math.max(0, props.numAcceptedFiles - props.uploadCount - props.rejected_uploads_state.length - props.failed_uploads_state.length)}</span>
                        </div>
                        </div>


                        <div style={{ marginTop: "20px" }}>
                        {props.rejected_uploads_state.length > 0 ? (
                            <>
                            <h2 style={{ fontSize: "20px", textAlign: "center", marginBottom: "10px" }}>First Few Rejected Uploads</h2>
                            <ul>
                                {props.rejected_uploads_state.slice(0, 5).map((file, index) => (
                                <li key={index} style={{ fontSize: "18px", marginBottom: "5px" }}>{file}</li>
                                ))}
                            </ul>
                            </>
                        ) : props.failed_uploads_state.length > 0 ? (
                            <>
                            <h2 style={{ fontSize: "20px", textAlign: "center", marginBottom: "10px" }}>First Few Failed Uploads</h2>
                            <ul>
                                {props.failed_uploads_state.slice(0, 5).map((file, index) => (
                                <li key={index} style={{ fontSize: "18px", marginBottom: "5px" }}>{file}</li>
                                ))}
                            </ul>
                            </>
                        ) : null}
                        </div>


                    </div>
                    </> : <>
                    <div></div>
                    </>

                }
                {/*<ul>{files}</ul>*/}
            </aside>
        </section>
    )
}

export default StudyUpload