import React from "react";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Grid } from "@material-ui/core";
import Button from '@material-ui/core/Button';
import MyTable from "../table.js";
import FilterListIcon from '@mui/icons-material/FilterList';

const RenderTables = (props) => {
  return (
    <Grid sx={{ maxWidth: '94%' }} direction="row" justifyContent="space-between" alignItems="stretch" container>
      <Grid item xs={8}>
        <Typography sx={{paddingTop: '0.5', fontSize: 16}} variant='h5'><b>ACTIVE FILTERS</b></Typography>
        <Box flexWrap='wrap'>
          {
          props.activeFilters["age"]?.length ?
            <Button onClick={() => {props.setShowFilter(2); props.setCollapseNav(true); }} style={{backgroundColor: '#009be5', margin: "5px 5px"}} >
            <Typography sx={{ }} style={{textTransform: 'none', fontSize: 12}} color="secondary" variant="p">
              Ages: {props.activeFilters["age"][0]} - {props.activeFilters["age"][1]}
            </Typography>
            </Button>
          : <> </>
          } 
          {
          props.activeFilters["requirestudies"] ?
            <Button onClick={() => {props.setShowFilter(2); props.setCollapseNav(true); }} style={{backgroundColor: '#009be5',  margin: "5px 5px"}}>
            <Typography sx={{ }} style={{textTransform: 'none', fontSize: 12}} color="secondary" variant="p">
              Require DICOMs
            </Typography>
            </Button>
          : <> </>
          }
          {
          props.activeFilters["customids"]?.length ?
            <Button onClick={() => {props.setShowFilter(2); props.setCollapseNav(true); }} style={{backgroundColor: '#009be5',  margin: "5px 5px"}}>
            <Typography sx={{ }} style={{textTransform: 'none', fontSize: 12}} color="secondary" variant="p">
              Custom ID List
            </Typography>
            </Button>
          : <> </>
          }
          {
          Object.keys(props.activeFilters["filters"]).map(key => (
            props.activeFilters["filters"][key].map(filter => (
              <Button onClick={() => {props.setShowFilter(2); props.setCollapseNav(true); }} key={filter} style={{backgroundColor: '#009be5', margin: "5px 5px"}}>
              <Typography sx={{ }} style={{textTransform: 'none', fontSize: 12}} color="secondary" variant="p">
                {filter}
              </Typography>
              </Button>
            ))
          ))
          }
        </Box>
      </Grid>
      <Grid container item xs={4} direction="row" justifyContent="flex-end" alignItems="flex-end">
        <Button onClick={() => {props.setShowFilter(2); props.setCollapseNav(true); }}  style={{width: 'fit-content',}} disableRipple  variant='outlined' color='primary'>
          <FilterListIcon sx={{marginRight: 0.5}}></FilterListIcon>
          <Typography sx={{paddingTop: '0.5'}} variant='p'>Filter</Typography>
        </Button>
      </Grid>
      <Grid item xs={12} >
        <MyTable
          activeFilters={props.activeFilters}
          setEditing={props.setEditing}
          setOpenAlert={props.setOpenAlert}
          allowed_colummns={props.allowed_colummns}
          FilterUpdate={props.FilterUpdate}
          userGroup={props.userGroup}
          setLoaded={props.setLoaded}
          loaded={props.loaded}
          table_name={props.singleName}
          set_patient_id={props.set_patient_id}
          authToken={props.authToken}
          logoutCallback={props.logoutCallback}
          setHeaderTitle={props.setHeaderTitle}/>
      </Grid>
      
    </Grid>
  )
}

export default RenderTables