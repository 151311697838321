import { useState, useEffect } from "react"
import { Grid } from "@material-ui/core";
import { Select } from '@material-ui/core';
import MenuItem from '@mui/material/MenuItem';
import { Button, Typography } from "@mui/material";
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { urls } from "../constants";

const CSVUpload = (props) => {

    return (
        <section className="container">
            <Grid display="block" direction="column" container>
                <Grid item >
                    <h4>TABLE SELECTION</h4>
                </Grid>

                <Grid item>
                    <p>1. Choose the table being uploaded.</p>
                </Grid>

                <Grid item>
                    <Select inputProps={{fontSize: 16}} 
                    variant="filled" 
                    style={{fontSize: 16, width: 300, height: 50, backgroundColor: 'white', border: 3, borderColor: 'black', border: "1px solid black",
                    color: "black",
                    "& .MuiSvgIcon-root": {
                        color: "black",
                        fontSize: 16
                    },}} 
                    labelId='table' 
                    onChange={props.handleTableSelect} 
                    value={props.table}>
                    <MenuItem style={{fontSize: 16}} value={"patient"}>{props.tableNames.patient}</MenuItem>
                    <MenuItem style={{fontSize: 16}} value={"cmr"}>{props.tableNames.cmr}</MenuItem>
                    <MenuItem style={{fontSize: 16}} value={"echo"}>{props.tableNames.echo}</MenuItem>
                    <MenuItem style={{fontSize: 16}} value={"cath"}>{props.tableNames.cath}</MenuItem>
                    <MenuItem style={{fontSize: 16}} value={"ecg"}>{props.tableNames.ecg}</MenuItem>
                    <MenuItem style={{fontSize: 16}} value={"cpet"}>{props.tableNames.cpet}</MenuItem>
                    <MenuItem style={{fontSize: 16}} value={"procedure"}>{props.tableNames.procedure}</MenuItem>
                    <MenuItem style={{fontSize: 16}} value={"clinicalnotes"}>{props.tableNames.clinicalnotes}</MenuItem>
                    </Select>
                </Grid>

                <Grid item style={{width: 700}}>
                    <p style={{paddingTop: 15}}> 
                    2. Choose a file to upload.
                    <ul style={{paddingLeft: 45, listStyleType: 'disc'}}>
                        <li>The file is scanned before uploading.</li> 
                        <li>Basic errors that need to be fixed before uploading will display as an error message.</li>
                        <li>Fix any errors and try to upload again.</li>
                    </ul>
                    </p>
                </Grid>

                <Grid item>
                    <Button onClick={props.handleCSVClick} style={{border: 'grey', borderStyle: 'dashed',backgroundColor: "white", paddingTop: 30,paddingBottom: 30, paddingLeft: 150, paddingRight: 150 ,marginRight: 3, marginTop: 15, width: 'fit-content', 
                    '&:hover': {
                        backgroundColor: '#009be5',
                        color: '#3c52b2',
                    },
                    }}>
                    <Grid container display="block">
                        <Grid item>
                        <FileUploadOutlinedIcon style={{fontSize: 30, color: '#009be5',}}></FileUploadOutlinedIcon>
                        </Grid>

                        <Grid item>
                        <Typography variant='h5' style={{ fontSize: 12, textTransform: 'none', color: 'black'}}>
                            Click to browse files
                        </Typography>
                        </Grid>
                    </Grid>
                    
                    </Button>
                    <input
                    type="file"
                    onChange={props.csvUpload}
                    ref={props.hiddenFileInput}
                    style={{display:'none'}}
                    accept=".csv"
                    />
                </Grid>

                <Grid item style={{width:700}}>
                    <p style={{marginTop: 15}}>
                    3. After basic errors are fixed by the user, the file is received by the database and checked for integrity.
                    <ul style={{paddingLeft: 45, marginBottom: 5, listStyleType: 'disc'}}>
                        <li style={{marginTop: 5}}>See the upload status page to check if your upload was successful.</li> 
                        <li>If the status page shows errors, the file needs to be re-uploaded after being fixed.</li>
                    </ul>
                    <b>Notes</b>
                    <ul style={{paddingLeft: 45, marginTop: 5, listStyleType: 'disc'}}>
                        <li>If the patient/age combination already exists in a table, all cells in that row will be replaced from the table being uploaded.</li> 
                        <li>The existing age values cannot be modified via CSV upload. A new row will be added if the age for an existing patient does not exactly match an existing age value.</li>
                    </ul>
                    </p>
                </Grid>                  
            </Grid>
        
        <aside>
            
            <ul>{props.csvFile}</ul>
        </aside>

        {props.csvGoodFile && 
            <Button onClick={() => { props.handleListItemClick(this, "CSV Upload Status"); props.handleLinking(this, "CSV Upload Status")}} style={{textTransform: 'none', backgroundColor: "#009be5", marginTop: 3, marginBottom: 30}} sx={{ paddingTop: 1, paddingRight: 1,marginRight: 3, width: 'fit-content', 
            '&:hover': {
                backgroundColor: '#009be5',
                color: '#3c52b2',
                },
            }}>
                <Typography sx={{ fontSize: 16, color: "white"}} variant="p">
                Check Status
                </Typography>
            </Button>
        }
    </section>
    )
}

export default CSVUpload