/*
 * Contributors: Jonathan Smith
 *
 * Libraries used: MUI React
 *
 * File description: Collapsed version of the navigation bar
 */

import * as React from 'react';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import { ExitToApp } from '@material-ui/icons';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import BuildOutlinedIcon from '@mui/icons-material/BuildOutlined';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import { Link } from '@material-ui/core';

const item = {
  py: '2px',
  px: 3,
  color: 'rgba(255, 255, 255, 0.7)',
  '&:hover': {
    bgcolor: 'rgba(255, 255, 255, 0.08)',
  },
};

const itemCategory = {
  boxShadow: '0 -1px 0 rgb(255,255,255,0.1) inset',
  py: 1.5,
  px: 3,
};

const getListItem = (ID) => {
  return document.getElementById(ID);
}

const NavigatorCollapse = (props) => {
  const { ...other } = props;

  /* 
  * Tabs are dynamically created from this array
  * new tabs can be added by creating a new entry
  * however a few things must also be changed 
  * 1. Go the handleNavtabs function in App.js and create a new case
  *    making sure to give it a unique number for the setNavTabs variable
  * 2. In Paperbase.js follow the format to add a new condition for your page 
  *    so when navTabs = your number it will render your page.
  */

  const categoryTables = { id: 'Tables', icon: <TableChartOutlinedIcon style={{ fontSize: 30, color: 'white', }} /> };
  const categoryUpload = { id: 'Upload', active: false, icon: <FileUploadOutlinedIcon style={{ fontSize: 30, color: 'white', }} /> };
  const categoryDownload = { id: 'Download', active: false, icon: <FileDownloadOutlinedIcon style={{ fontSize: 30, color: 'white', }} /> };
  const categoryTools = {id: 'Tools', active: false, icon: <BuildOutlinedIcon style={{ fontSize: 30, color: 'white'}} />}
  const categoryAdmin = { id: 'Admin', active: false, icon: <AdminPanelSettingsOutlinedIcon style={{ fontSize: 30, color: 'white', }} /> };

  const categoryLogout = {
    id: 'second',
    children: [
      { id: 'Logout', active: false,icon: <ExitToApp style={{fontSize: 30, color: 'white',}}></ExitToApp> , func: props.logoutKeyPress },
    ],
  };

  const permissionCategoryMappings = [
    { permission: "capchd-upload", category: categoryUpload },
    { permission: "capchd-download", category: categoryDownload },
    { permission: "capchd-admin", category: categoryAdmin },
  ];

  const categoriesToShow = [
    { id: 'first', children: [categoryTables] },
    categoryLogout
  ];

  for (var mapping of permissionCategoryMappings) {
    if (props.userGroup.findIndex(element => element === mapping.permission) !== -1) {
      categoriesToShow[0].children.push(mapping.category);
    }
  }
  const lastIndex = categoriesToShow[0].children.length-1;
  if(categoriesToShow[0].children[lastIndex]?.id == 'Admin'){
    categoriesToShow[0].children.splice(lastIndex, 0, categoryTools);
  } else {
    categoriesToShow[0].children.push(categoryTools);
  }
  
  return (
    <Drawer style={{flexDirection: "column"}} variant="permanent" {...other}>
      <div style={{ flexDirection: "column", display: "flex", justifyContent: "center", alignItems: "center", height: "100%"}}>
        <div>
          <List disablePadding>
            <Divider component="li" sx={{ mt: 2, border: "0.5px solid grey" }} />
            
            {categoriesToShow.map(({ id, children }) => (
              <Box key={id} sx={{ bgcolor: '#101F33' }}>
                <List style={{width: 74}}>
                {children.map(({ id: childId, icon, func }) => (
                  childId === 'Tables' ?
                    <ListItem sx={{paddingTop: 2, px: 0, paddingBottom: 0}} onMouseOver={(e) => {props.setCollapseTable(true); props.setCollapseUpload(false); props.setCollapseAdmin(false); props.setCollapseDownload(false); props.setCollapseTools(false);}} id={childId} disablePadding key={childId}>
                      <ListItemButton selected={props.selectedIndex === childId} sx={item}>
                        <ListItemIcon>{icon}</ListItemIcon>
                      </ListItemButton>
                    </ListItem>
                  : childId === 'Upload' ?
                  <ListItem sx={{paddingTop: 2, px: 0, paddingBottom: 0}} onMouseOver={(e) => {props.setCollapseTable(false); props.setCollapseUpload(true); props.setCollapseAdmin(false); props.setCollapseDownload(false); props.setCollapseTools(false);}} id={childId} disablePadding key={childId}>
                    <ListItemButton selected={props.selectedIndex === childId} sx={item}>
                      <ListItemIcon>{icon}</ListItemIcon>
                    </ListItemButton>
                  </ListItem>
                  : childId === 'Admin' ?
                  <ListItem sx={{paddingTop: 2, px: 0, paddingBottom: 0}} onMouseOver={(e) => {props.setCollapseTable(false); props.setCollapseUpload(false); props.setCollapseAdmin(true); props.setCollapseDownload(false); props.setCollapseTools(false);}} id={childId} disablePadding key={childId}>
                    <ListItemButton selected={props.selectedIndex === childId} sx={item}>
                      <ListItemIcon>{icon}</ListItemIcon>
                    </ListItemButton>
                  </ListItem>
                  : childId === 'Download' ?
                  <ListItem sx={{paddingTop: 2, px: 0, paddingBottom: 0}} onMouseOver={(e) => {props.setCollapseTable(false); props.setCollapseUpload(false); props.setCollapseAdmin(false); props.setCollapseDownload(true); props.setCollapseTools(false);}} id={childId} disablePadding key={childId}>
                    <ListItemButton selected={props.selectedIndex === childId} sx={item}>
                      <ListItemIcon>{icon}</ListItemIcon>
                    </ListItemButton>
                  </ListItem>
                  : childId === 'Tools' ?
                  <ListItem sx={{paddingTop: 2, px: 0, paddingBottom: 0}} onMouseOver={(e) => {props.setCollapseTable(false); props.setCollapseUpload(false); props.setCollapseAdmin(false); props.setCollapseDownload(false); props.setCollapseTools(true);}} id={childId} disablePadding key={childId}>
                    <ListItemButton selected={props.selectedIndex === childId} sx={item}>
                      <ListItemIcon>{icon}</ListItemIcon>
                    </ListItemButton>
                  </ListItem>
                  :
                  <>
                  <ListItem sx={{paddingTop: 2, px: 0, paddingBottom: 0}} onClick={(e) => {func();}} onMouseOver={(e) => {props.setCollapseTable(false); props.setCollapseUpload(false); props.setCollapseAdmin(false); props.setCollapseDownload(false); props.setCollapseTools(false);}} id={childId} disablePadding key={childId}>
                    <ListItemButton selected={props.selectedIndex === childId} sx={item}>
                      <ListItemIcon>{icon}</ListItemIcon>
                    </ListItemButton>
                  </ListItem>
                  </>
                ))}
                </List>
                <Divider component="li" sx={{ mt: 2, border: "0.5px solid grey" }} />
              </Box>
            ))}
          </List>
        </div>
      </div>
      <ListItem  onMouseOver={(e) => {props.setCollapseTable(false); props.setCollapseUpload(false)}} onClick={props.handleCollapseNavDefault} sx={{ py: 2, px: 3, marginTop: 'auto', ...item, ...itemCategory }}>
        <ListItemIcon><KeyboardDoubleArrowRightIcon style={{fontSize: 30, color: 'white',}} /></ListItemIcon>
      </ListItem>
    </Drawer>
  );
}

export default NavigatorCollapse;