/*
 * Contributors: Jonathan Smith
 *
 * Libraries used: MUI react, 
 *
 * File description: Contains react element for the search bar when looking up single patients
 */

import * as React from 'react';
import { AppBar } from '@mui/material';
import  Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import MyTable from '../table';
import { tableNames } from '../constants';
import { Button, Typography } from "@mui/material";
import { urls } from "../constants";
import { SaveAlt } from "@material-ui/icons";

const Content = (props) => {
  const valid_tables = Object.values(tableNames);

  const base_url = urls.base_url_query_table;

  const url = `${base_url}?cmd=get_single_patient_data&patient_id=${props.patient_id}`

  const handleResetID = () => {
    props.set_patient_id("");
  }

  const handleDownload = async () => {
    try {
    const response = await fetch(url, {
      headers: {
        Authorization: props.authToken
      }
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const blob = await response.blob();
    const blobURL = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = blobURL;
    link.download = `${props.patient_id}.csv`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    } catch (error) {
      console.error("There was an error downloading the CSV:", error);
    }
  }

  return (
    props.patient_id !== "" ?
    <>
    <Button onClick={handleResetID} style={{backgroundColor: '#009be5', margin: "5px 5px"}}>
    <Typography sx={{ }} style={{textTransform: 'none', fontSize: 12}} color="secondary" variant="p">
        Back to Search
    </Typography>
    </Button>

    <Button onClick={handleDownload} style={{backgroundColor: '#009be5', margin: "5px 5px"}} startIcon={<SaveAlt style={{ color: '#FFF' }} />}>
      <Typography sx={{ }} style={{textTransform: 'none', fontSize: 12}} color="secondary" variant="p">
          Download all tables for this patient
      </Typography>
    </Button>
    
    {valid_tables.map(table_item => (
      <MyTable
        setEditing={props.setEditing}
        setOpenAlert={props.setOpenAlert}
        allowed_colummns={props.allowed_colummns}
        setLoaded={props.setLoaded}
        loaded={props.loaded}
        patient_id={props.patient_id}
        set_patient_id={props.set_patient_id}
        table_name={table_item}
        authToken={props.authToken}
        logoutCallback={props.logoutCallback}
        setHeaderTitle={props.setHeaderTitle}
        userGroup={props.userGroup}
        FilterUpdate={props.FilterUpdate}/>
    ))}
    </>
    :
    <>
    <Paper sx={{ maxWidth: 936, margin: 'auto', overflow: 'hidden' }}>
      <AppBar
        position="static"
        color="default"
        elevation={0}
        sx={{ borderBottom: '1px solid rgba(50, 0, 0, 0.12)' }}
      >
        {/* Start of search bar */}
        <Toolbar>
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <SearchIcon color="inherit" sx={{ display: 'block' }} />
            </Grid>
            <Grid item xs>
              <TextField
                fullWidth
                placeholder={props.placeHolderText}
                InputProps={{
                  disableUnderline: true,
                  sx: { fontSize: 'default',  },
                }}
                variant="standard"
                default={props.search}
                onKeyDown={props.search}
              />
            </Grid>
            <Grid item>
              
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      {/*<Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="center">
      <MyTable table_name="Patient" set_patient_id={set_patient_id} authToken={authToken} logoutCallback={logoutCallback} setNavTabs={setNavTabs}/>
        </Typography>*/}
    </Paper>
    </>
  );
}

export default Content;
