import { TextField } from "@material-ui/core";
import React, {useState} from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ReactLoading from 'react-loading';

const StudyDelete = (props) => {

    const [open, setOpen] = useState(false);
    const [studyID, setStudyID] = useState("");
    const [loading, setLoading] = useState(false);

    const openConfirmationDialog = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const ConfirmationDialog = ({open, handleClose, studyID}) => {
        return (
            <>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title" style={{fontSize: "24px", paddingBottom: "2px"}}>
                    {"Delete Confirmation"}
                    </DialogTitle>
                    <DialogContent style={{paddingBottom: "5px"}}>
                        <DialogContentText id="alert-dialog-description" style={{fontSize: "15px", paddingBottom: "5px"}}>
                            {`Are you sure you want to delete the study folder titled `}
                            <span style={{fontWeight: 600}}>{`${studyID}`}</span>
                            {`?`}
                        </DialogContentText>
                        <DialogContentText id="alert-dialog-description" style={{fontSize: "15px"}}>
                            {`Deleting this folder will permanently remove all its contents, including any subfolders and files contained within it. This action cannot be undone.`}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button onClick={() => {
                            handleClose();
                            setStudyID("")
                            deleteStudy(studyID);
                        }}>Delete</Button>
                    </DialogActions>
                </Dialog>
            </>
        )
    }

    const checkStudyID = (e) => {
        if (e.keyCode === 13) {

            const studyIDRegex = /^CHD\d{7}$/;
            const studyID = e.target.value;

            if(!studyIDRegex.test(studyID)) {
                props.setOpenAlert(23);
                return;
            }

            if (loading) {
                props.setOpenAlert(28);
                return;
            }

            const url = `${props.baseUrl}?cmd=get_study_info&study_id=${studyID}`;

            fetch(url, { headers: { Authorization: props.authToken } })
            .then((response) => response.json())
            .then((data) => {
                const foundStudy = Object.keys(data).some(key => key.includes(studyID));
                if (foundStudy) {
                    openConfirmationDialog();
                } else {
                    props.setOpenAlert(24);
                }
            })
            .catch(err => {
                props.setOpenAlert(24);
            });
        }
    }

    const deleteStudy = async (studyID) => {
        const url = `${props.baseUrl}?cmd=delete_study&study_id=${studyID}`;

        setLoading(true);
        props.setOpenAlert(27);

        await fetch(url, { headers: { Authorization: props.authToken } })
        .then((response) => response.json())
        .then((data) => {
            if (data.deleted) {
                props.setOpenAlert(26);
            } else {
                props.setOpenAlert(25);
            }
        })
        .catch(err => {
            props.setOpenAlert(25);
        });

        setLoading(false);
    }

    return (
        <>
            <p style={{marginTop:-30}}>Enter study folder ID here. A prompt will appear to confirm study folder deletion.</p>
            <TextField
                InputProps={{ style: { fontSize: 14 } }}
                InputLabelProps={{ style: { fontSize: 14 } }} 
                label="Enter a single study folder ID (CHDXXXXXXX) here, and then press <enter>" variant="outlined" 
                value={studyID}
                onChange={(event) => setStudyID(event.target.value)}
                onKeyDown={checkStudyID} style = {{width: 1000}}/>
            <p style={{marginTop:10, marginBottom: 0}}><b>Notes:</b></p>
            <ul style={{fontSize: 14, listStyleType: "disc", marginTop: 0, marginLeft: 30}}>
                <li>Larger Study folders might take a few minutes to be deleted from the database.</li>
                <li>Study deletion will unlink the study ID from the CMR table.</li>
                <li>Study will need to manually be linked again after upload.</li>
            </ul>
            <ConfirmationDialog open={open} handleClose={handleClose} studyID={studyID} />
            {loading && <ReactLoading type="spin" color="#009be5" height={667} width={375} />}
        </>
    );
}

export default StudyDelete 
