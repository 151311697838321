import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { LicenseInfo } from '@mui/x-license-pro';
import { HashRouter } from "react-router-dom";
import { isProd } from "./components/constants";

LicenseInfo.setLicenseKey('33bfadabf13786f43a44f8cb993edd5bTz00NTI4OSxFPTE2ODYyNTg3MjEzNzUsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

if (isProd && window.console) {
  var noOp = function(){}; // no-op function
  console = {
    log: noOp,
    warn: noOp,
    error: noOp
  };
}

ReactDOM.render(
  <React.StrictMode>
    <HashRouter>
        <App />
    </HashRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
