import { TextField } from "@material-ui/core";
import React, {useState, useRef} from "react";
import { Grid } from "@material-ui/core";
import { Button, Typography } from "@mui/material";
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Select } from '@material-ui/core';
import MenuItem from '@mui/material/MenuItem';
import ReactLoading from 'react-loading';

const AttachmentUpload = (props) => {

    const style = props.style;

    const validatePatientID = (e) => {
        if (e.keyCode === 13) {
            props.setValidID(false);
            props.setAge("");
            props.setAges([]);

            const patientIDRegex = /^CHD\d{5}$/;
            const patientID = e.target.value;

            if(!patientIDRegex.test(patientID)) {
                props.setOpenAlert(14);
                return;
            }

            const url = `${props.baseUrl}?cmd=query_from_table&table_name=${props.tableNames.clinicalnotes}`;

            fetch(url, { 
                method: "POST",
                headers: { 
                    'Authorization': props.authToken,
                    'Content-Type': 'application/json' 
                },
                body: JSON.stringify({"filters":{},"requirestudies":false,"age":[],"customids":[]})
            })
            .then((response) => response.json())
            .then((data) => {
                const rows = data[1];
                let ages = [];

                for (const row of rows) {
                    if (row["ID"] === patientID) {
                        ages.push(row["age"]);
                    }
                }

                if (ages.length > 0) {
                    props.setAges(ages);
                    props.setValidID(true);
                } else {
                    props.setOpenAlert(29);
                }
            })
            .catch((err) => {
                props.setOpenAlert(29);
            });
        }
    }

    return (
        <>
            <h4>Follow the steps to upload files for Clinical Notes Attachments:</h4>
            <Grid item style={{marginTop: 20}}>
                <p>1. Enter a valid patient ID here.</p>
            </Grid>
            <TextField
                InputProps={{ style: { fontSize: 14 } }}
                InputLabelProps={{ style: { fontSize: 14 } }} 
                label="Enter a single patient ID (CHDXXXXX) here, and then press <enter>" variant="outlined" 
                value={props.patientID}
                onChange={(event) => {
                    const patientIDRegex = /^CHD\d{5}$/;

                    if (patientIDRegex.test(props.patientID) && props.patientID !== event.target.value) {
                        props.setValidID(false);
                        props.setAge("");
                        props.setAges([]);
                    }
                    props.setPatientID(event.target.value);
                }}
                onKeyDown={validatePatientID} 
                style = {{width: 1000}}
            />

            <Grid item style={{marginTop: 20}}>
                <p>2. Select the age of the patient.</p>
            </Grid>
            <Grid item>
                <Select 
                inputProps={{fontSize: 16}} 
                variant="filled" 
                style={{opacity: !props.validID ? 0.5 : 1, fontSize: 16, width: 200, height: 50, backgroundColor: 'white', border: 3, borderColor: 'black', 
                border: "1px solid black", color: "black",
                "& .MuiSvgIcon-root": {
                    color: "black",
                    fontSize: 16
                }}} 
                labelId='Ages' 
                onChange={(event) => {
                    props.setAge(event.target.value);
                    props.setTable("");
                }} 
                value={props.age}
                disabled={!props.validID}
                >
                    {props.ages.map(age => (
                        <MenuItem style={{fontSize: 16}} value={age}>{age}</MenuItem>
                    ))}
                </Select>
            </Grid>

            <Grid item style={{marginTop: 20}}>
                <p>3. Choose one or more files to upload.</p>
            </Grid>
            <div {...props.getRootProps({style})} style={{ opacity: !props.age ? 0.5 : 1, border: 'grey dashed', backgroundColor: "white", paddingTop: 30, paddingBottom: 30, paddingLeft: 150, paddingRight: 150, marginRight: 3, borderRadius: 10, width: 'fit-content', cursor: !props.age ? 'default' : 'pointer'}} 
            disabled={!props.age}
            >
                <input {...props.getInputProps()} multiple type="file" accept="" disabled={!props.age}/>
                <Grid container style={{display:"flex", flexDirection:"row", alignItems: "center"}}>
                    <Grid item>
                        <FileUploadOutlinedIcon style={{fontSize: 30, color: '#009be5',}}></FileUploadOutlinedIcon>
                    </Grid>
                    <Grid item>
                        <Typography variant='h5' style={{ fontSize: 12, textTransform: 'none', color: 'black'}}>
                            Click to browse files
                        </Typography>
                    </Grid>
                </Grid>
            </div>
            <aside>
                {
                    props.numAcceptedFiles !== 0 ? <>
                    <div>
                        <h1 style={{ fontSize: "24px", textAlign: "center", marginBottom: "20px" }}>
                        Upload Status
                        </h1>
                    
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "20px" }}>
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                            <span style={{ fontSize: "18px", fontWeight: "bold" }}>Total attempted uploads</span>
                            <span style={{ fontSize: "36px", fontWeight: "bold", color: "#333" }}>{props.numAcceptedFiles}</span>
                        </div>
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                            <span style={{ fontSize: "18px", fontWeight: "bold" }}>Successful uploads</span>
                            <span style={{ fontSize: "36px", fontWeight: "bold", color: "#4caf50" }}>{props.uploadCount}</span>
                        </div>
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                            <span style={{ fontSize: "18px", fontWeight: "bold" }}>Rejected uploads</span>
                            <span style={{ fontSize: "36px", fontWeight: "bold", color: "#ff9800" }}>{props.rejected_uploads_state.length}</span>
                        </div>
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                            <span style={{ fontSize: "18px", fontWeight: "bold" }}>Failed uploads</span>
                            <span style={{ fontSize: "36px", fontWeight: "bold", color: "#f44336" }}>{props.failed_uploads_state.length}</span>
                        </div>
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                            <span style={{ fontSize: "18px", fontWeight: "bold" }}>Remaining uploads</span>
                            <span style={{ fontSize: "36px", fontWeight: "bold", color: "#2196f3" }}>{Math.max(0, props.numAcceptedFiles - props.uploadCount - props.rejected_uploads_state.length - props.failed_uploads_state.length)}</span>
                        </div>
                        </div>


                        <div style={{ marginTop: "20px" }}>
                        {props.rejected_uploads_state.length > 0 ? (
                            <>
                            <h2 style={{ fontSize: "20px", textAlign: "center", marginBottom: "10px" }}>First Few Rejected Uploads</h2>
                            <ul>
                                {props.rejected_uploads_state.slice(0, 5).map((file, index) => (
                                <li key={index} style={{ fontSize: "18px", marginBottom: "5px" }}>{file}</li>
                                ))}
                            </ul>
                            </>
                        ) : props.failed_uploads_state.length > 0 ? (
                            <>
                            <h2 style={{ fontSize: "20px", textAlign: "center", marginBottom: "10px" }}>First Few Failed Uploads</h2>
                            <ul>
                                {props.failed_uploads_state.slice(0, 5).map((file, index) => (
                                <li key={index} style={{ fontSize: "18px", marginBottom: "5px" }}>{file}</li>
                                ))}
                            </ul>
                            </>
                        ) : null}
                        </div>


                    </div>
                    </> : <>
                    <div></div>
                    </>

                }
                {/*<ul>{files}</ul>*/}
            </aside>
        </>
    );
}

export default AttachmentUpload;