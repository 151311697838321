import React, {useState, useRef} from "react";
import { TextField } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import { Button, Typography } from "@mui/material";
import { Select } from '@material-ui/core';
import MenuItem from '@mui/material/MenuItem';
import ReactLoading from 'react-loading';

const AttachmentDownload = (props) => {

    const [patientID, setPatientID] = useState("");
    const [validID, setValidID] = useState(false);
    const [ages, setAges] = useState([]);
    const [age, setAge] = useState("");
    const [loading, setLoading] = useState(false);

    const validatePatientID = (e) => {
        if (e.keyCode === 13) {
            setValidID(false);
            setAge("");
            setAges([]);

            const patientIDRegex = /^CHD\d{5}$/;
            const patientID = e.target.value;

            if(!patientIDRegex.test(patientID)) {
                props.setOpenAlert(14);
                return;
            }

            const url = `${props.baseQueryUrl}?cmd=query_from_table&table_name=${props.tableNames.clinicalnotes}`;

            fetch(url, { 
                method: "POST",
                headers: { 
                    'Authorization': props.authToken,
                    'Content-Type': 'application/json' 
                },
                body: JSON.stringify({"filters":{},"requirestudies":false,"age":[],"customids":[]})
            })
            .then((response) => response.json())
            .then((data) => {
                const rows = data[1];
                let ages = [];

                for (const row of rows) {
                    if (row["ID"] === patientID && row["File Attachments"]) {
                        ages.push(row["age"]);
                    }
                }

                if (ages.length > 0) {
                    setAges(ages);
                    setValidID(true);
                } else {
                    props.setOpenAlert(29);
                }
            })
            .catch((err) => {
                props.setOpenAlert(29);
            });
        }
    }

    const handleDownload = async () => {
        setLoading(true);
        const url = `${props.downloadUrl}patient_ids=OtherNotes/${patientID}/${age}`;
        console.log("Download URL:", url);

        await fetch(url, { headers: { Authorization: props.authToken } })
        .then((response) => response.json())
        .then((data) => {
            // console.log("Download URL:", data);
            const link = document.createElement('a');
            link.href = data;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            setLoading(false);
        })
        .catch(err => {
          props.setOpenAlert(30);
        });
    }

    return (
        <>
            <h4>Follow the steps to download files for Clinical Notes Attachments:</h4>
            <Grid item style={{marginTop: 20}}>
                <p>1. Enter a valid patient ID here.</p>
            </Grid>
            <TextField
                InputProps={{ style: { fontSize: 14 } }}
                InputLabelProps={{ style: { fontSize: 14 } }} 
                label="Enter a single patient ID (CHDXXXXX) here, and then press <enter>" variant="outlined" 
                value={patientID}
                onChange={(event) => {
                    const patientIDRegex = /^CHD\d{5}$/;

                    if (patientIDRegex.test(patientID) && patientID !== event.target.value) {
                        setValidID(false);
                        setAge("");
                        setAges([]);
                    }
                    setPatientID(event.target.value);
                }}
                onKeyDown={validatePatientID} 
                style = {{width: 1000}}
            />

            <Grid item style={{marginTop: 20}}>
                <p>2. Select the age of the patient.</p>
            </Grid>
            <Grid item>
                <Select 
                inputProps={{fontSize: 16}} 
                variant="filled" 
                style={{opacity: !validID ? 0.5 : 1, fontSize: 16, width: 200, height: 50, backgroundColor: 'white', border: 3, borderColor: 'black', 
                border: "1px solid black", color: "black",
                "& .MuiSvgIcon-root": {
                    color: "black",
                    fontSize: 16
                }}} 
                labelId='Ages' 
                onChange={(event) => setAge(event.target.value)} 
                value={age}
                disabled={!validID}
                >
                    {ages.map(age => (
                        <MenuItem style={{fontSize: 16}} value={age}>{age}</MenuItem>
                    ))}
                </Select>
            </Grid>

            <Button onClick={handleDownload} style={{textTransform: 'none', backgroundColor: age ? "rgba(0, 155, 229, 1)" : "rgba(0, 155, 229, 0.6)", marginTop: 20, marginBottom: 15}} sx={{ paddingTop: 1, paddingRight: 1,marginRight: 3, width: 'fit-content', 
            ':hover': {
            backgroundColor: '#000',
            color: '#3c52b2',
            }}}
            disabled={!age}
            >
                <Typography sx={{ fontSize: 16, color: "white"}} variant="p">
                    Download
                </Typography>
            </Button>
            {loading && <ReactLoading type="spin" color="#009be5" height={667} width={375} />}
        </>
    )
}

export default AttachmentDownload;